var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
var subsiteHijackMainNav = ['mentalhealthhub','tranquillity-care-services'];
var subsiteHijackMainLogoClickDestination = ['tranquillity-care-services'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links

if ($('body.subsite.tranquillity-care-services').length > 0) {
  $( ".pageHeader" ).wrap( "<div class='pageHeaderWrapper'></div>" );
}

if ($('body.subsite.tranquillity-care-services.homepage').length > 0) {
  $('document').ready(function(){
    // Resize images of Subsite banner
    $('.carouselSlide img').each(function () {
      var updatedSrc = $(this)
        .attr('src')
        .replace(/w=([0-9]*)&h=([0-9]*)/, 'w=1000&h=1000')
        .replace(/width=([0-9]*)&height=([0-9]*)/, 'width=1500&height=1500');
      $(this).prop('src', updatedSrc);
      $(this).prop('srcset', '');
    });
    $('.subsiteBody').prependTo( $('.headerWrapperSubsite') );
    $(".subsiteBody").insertBefore(".carousel");  
  })
}

